import BigNumber from 'bignumber.js';

import { makeContractValueStore } from './storeUtils';
import { makeManagedTransaction } from './transactionManager';

import { userAddressStore, contractInstanceDataStore } from './appStore';
import { steppedCurrentBlockTimestampStore } from './currentBlockStore';

export const [baconCoinBalanceStore, updateBaconCoinBalance] =
  makeContractValueStore({
    initialValue: new BigNumber('0'),
    getUpdateContractMethod: ({ userAddress, contractInstances }) => {
      const { baconCoinContractInstance } = contractInstances;
      return baconCoinContractInstance.methods.balanceOf(userAddress);
    },
    onUpdateSuccess: ({ val, store }) => {
      let bigValue = new BigNumber(val).shiftedBy(-18);
      store.set(bigValue);
    },
  });

export const [baconCoinAccruedStore, updateBaconCoinAccrued] =
  makeContractValueStore({
    initialValue: new BigNumber('0'),
    additionalUpstreamStores: [steppedCurrentBlockTimestampStore],
    getUpdateContractMethod: ({ userAddress, contractInstances }) => {
      const { poolStakingRewardsContractInstance } = contractInstances;
      return poolStakingRewardsContractInstance.methods.massHarvest(
        userAddress
      );
    },
    onUpdateSuccess: ({ val, store }) => {
      let bigValue = new BigNumber(val).shiftedBy(-18);
      store.set(bigValue);
    },
  });

export const claimBoostBaconRewardsTransaction = makeManagedTransaction({
  name: 'claimBoostBaconRewards',
  analyticsName: 'claim boost bacon rewards',
  category: 'BaconRewardsTransaction', // TODO: right?
  getContractMethod: (contractInstanceDataStore, transactionArgs) => {
    const userAddress = userAddressStore.get();
    const { poolStakingRewardsContractInstance } =
      contractInstanceDataStore.get();

    return poolStakingRewardsContractInstance.methods.massHarvest(userAddress);
  },
  onConfirmation: (transactionArgs) => {
    updateBaconCoinBalance();
    updateBaconCoinAccrued();
  },
});
