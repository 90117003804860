import '../../styles/form.css';
import '../../styles/basicStyles.css';

import { useStore } from '@nanostores/react';

import NumberFormat from 'react-number-format';

import { TransactionStatePanel, MessagePanel } from '../../components';

import logo from '../../assets/images/BaconCoin_Stable-Icon_01_LightBGs_500x500.png';

import {
  startManagedTransaction,
  transactionQueueStore,
} from '../../store/transactionManager';

import {
  baconCoinAccruedStore,
  claimBoostBaconRewardsTransaction,
} from '../../store/baconStore';

export const BaconRewardsForm = ({}) => {
  const totalBaconRewards = useStore(baconCoinAccruedStore);
  const transactions = useStore(transactionQueueStore);

  const transactionMarker = 'HomeRewardsTransaction';
  const transactionState = transactions
    ? transactions.find((transaction) => {
        return (
          transaction.active && transaction.uiData.marker === transactionMarker
        );
      })
    : null;

  const claimBacon = () => {
    startManagedTransaction(claimBoostBaconRewardsTransaction, {
      action: 'Claimed',
      amount: totalBaconRewards,
      marker: transactionMarker,
    });
  };

  const renderSuccessContent = (uiData, handleClose) => {
    return (
      <MessagePanel
        firstMessage={`You ${uiData.action}`}
        firstValue={`${uiData.amount.toFixed()} Bacon`}
        onClose={handleClose}
      />
    );
  };

  if (transactionState) {
    return (
      <div className="form">
        <TransactionStatePanel
          className="baconRewardsTransactionPanel"
          transactionState={transactionState}
          successContent={renderSuccessContent}
        />
      </div>
    );
  } else {
    return (
      <div className="form">
        <img src={logo} className="logo-form" alt="bacon logo" />
        <div className="balanceRow">
          <div>Unclaimed Bacon</div>
          <div className="balanceAmount">
            <NumberFormat
              displayType="text"
              value={totalBaconRewards.toFixed()}
              thousandSeparator={true}
              decimalScale={4}
            />
          </div>
        </div>
        <div className="noteText">Bacon rewards for Boosting HOME.</div>
        <div className="noteText">
          More Bacon rewards in <a href="/farming">Farming</a>
        </div>
        <button
          className="button centered claim"
          onClick={() => claimBacon()}
          disabled={totalBaconRewards.isZero()}
        >
          <div className="buttonText">Claim</div>
        </button>
      </div>
    );
  }
};

export default BaconRewardsForm;
